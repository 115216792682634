import React from "react";
import { Space, Row, Col, Avatar, Form, message, Button, Checkbox, Input, Radio, Typography, Modal, Menu, Tag, Collapse, Badge, Table, Popconfirm } from "antd";
import {
  EditOutlined,
  ArrowLeftOutlined,
  PhoneFilled,
  EnvironmentFilled,
  DownOutlined,
  DeleteOutlined,
  TruckOutlined,
  PrinterOutlined,
  ApiOutlined,
  FileProtectOutlined,
  SearchOutlined,
  SwapOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ChatData } from "../index";
import api from "api";
import AddressPicker from "component/live/address_picker";
import { formatCurrency, formatLongDate } from "common/Format2";
import JATCancelOrderPopup from "component/live/cancel_order_jat_popup";
import JATCreateOrderPopup from "component/live/jat_create_order_popup";
import ChangeSession from "component/live/popup_change_live_session";
import { Print } from "component/print";
import TrackingPopup from "./tracking_popup";
import dayjs from "dayjs";
import DiscountPopup from "./popup_discount";
import { createStyles } from "antd-style";
const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: #eaeaea transparent;
            scrollbar-gutter: stable;
          }
        }
      }
    `,
  };
});
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const { Text, Title, Link } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;
const { confirm } = Modal;

const CustomerChat = React.forwardRef((props, ref) => {
  const [mode, setMode] = React.useState("info");
  const [loading, setLoading] = React.useState(false);
  const [recordPrint, setRecordPrint] = React.useState({});
  const [typePrint, setTypePrint] = React.useState();
  const [cusPrint, setCusPrint] = React.useState({});
  const [addresCus, setAddressCus] = React.useState({});
  const [notes, setNotes] = React.useState();
  const [lstDetail, setLstDetail] = React.useState([]);
  const [note2, setNote2] = React.useState();
  const [form] = Form.useForm();
  const { selectedComment, setFuntionAddNotesRightChat, setFuntionUpdateAddressRightChat, handleContextMenu } = React.useContext(ChatData);
  const jatCreateOrderPopup = React.useRef();
  const jatCancelOrderPopup = React.useRef();
  const changeSession = React.useRef();
  const printRef = React.useRef();
  const trackingRef = React.useRef();
  const discountPopup = React.useRef();
  const { styles } = useStyle();

  const menu = (
    <Menu>
      <Menu.Item>
        <Text>Doanh số đầu kỳ: 0đ</Text>
      </Menu.Item>
      <Menu.Item>
        <Text>Doanh số: 1000đ</Text>
      </Menu.Item>
      <Menu.Item>
        <Text>Tổng doanh số: 1000đ</Text>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Mã",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "SL",
      dataIndex: "products",
      key: "products",
      render: function (text, record, index) {
        var products = record.products.split(", ");
        if (products.length == 1) products = record.products.split(",");
        var count = 0;
        products.forEach((x) => {
          var lstSplit = x.split(" ");
          count += lstSplit[0] * 1;
        });
        return <div>{count}</div>;
      },
    },
    {
      title: "Giá trị",
      dataIndex: "total_price",
      key: "total_price",
      render: function (text, record, index) {
        return formatCurrency(text);
      },
    },
    {
      title: "Mã vận đơn",
      dataIndex: "transpost_id",
      key: "transpost_id",
      render: (text, record, index) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              trackingRef.current.open(record.id);
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Trạng thái J&T",
      dataIndex: "transpost_status",
      key: "transpost_status",
      render: function (text, record, index) {
        if (record.jat_status) return <Tag color="green">{record.jat_status}</Tag>;
        else if (record.transpost_status == "created") {
          return <Tag color="green">Đã gửi đơn</Tag>;
        }
        if (record.transpost_status == "cancelled") {
          return <Tag color="orange">Đã hủy đơn</Tag>;
        }
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_time",
      key: "create_time",
      align: "center",
      render: function (text, record, index) {
        return (
          <div>
            <p>{record.name}</p>
            <p>{formatLongDate(text)}</p>
          </div>
        );
      },
    },
    {
      key: "action",
      align: "center",
      render: function (text, record, index) {
        return (
          <div>
            {props.roleId == 4 ? (
              <>
                <Button
                  type={"primary"}
                  onClick={() => {
                    props.setSelectedOrder(record);
                    props.onTabChange("2");
                  }}
                  icon={<EditOutlined />}
                />
              </>
            ) : (
              <>
                <div style={{ marginBottom: 10 }}>
                  <Space>
                    <Button
                      type={"primary"}
                      onClick={() => {
                        props.setSelectedOrder(record);
                        props.onTabChange("2");
                      }}
                      icon={<EditOutlined />}
                    />
                    <Popconfirm
                      title="Xóa đơn hàng"
                      description="Bạn có muốn xóa đơn hàng này?"
                      onConfirm={() => deleteOrder(record.id)}
                      okText="Đồng ý"
                      cancelText="Không"
                    >
                      <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                    <Button
                      type={"primary"}
                      onClick={async () => {
                        var data = await api.get("LiveOrder/GetListOrderDetail?id=" + record.id);
                        if (data.code == 1) setLstDetail(data.data);
                        setRecordPrint(record);
                        setTypePrint("bill_live_order");
                        setTimeout(() => {
                          printRef.current.print();
                        }, 500);
                      }}
                      icon={<PrinterOutlined />}
                    ></Button>
                  </Space>
                </div>
                <div>
                  <Space>
                    <Button
                      type={"primary"}
                      onClick={() => {
                        changeSession.current.open(record.id);
                      }}
                      icon={<SwapOutlined />}
                    ></Button>
                    {record.customer_phone && record.customer_address && record.customer_address_code && record.transpost_status != "created" ? (
                      <Button
                        type={"primary"}
                        onClick={() => {
                          jatCreateOrderPopup.current.open(record.id);
                        }}
                        icon={<TruckOutlined />}
                      />
                    ) : (
                      <></>
                    )}
                    {record.transpost_status == "created" ? (
                      <>
                        <Button
                          icon={<ApiOutlined />}
                          type={"primary"}
                          onClick={() => {
                            jatCancelOrderPopup.current.open(record.id);
                          }}
                        ></Button>
                        <Button
                          icon={<FileProtectOutlined />}
                          type={"primary"}
                          onClick={() => {
                            if (!record.customer_id) {
                              message.error("Dữ liệu đã cũ.");
                              return;
                            }
                            printBillJAT(record);
                          }}
                        ></Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </Space>
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const columDiscount = [
    {
      title: "Mã",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      render: (text, record, index) => {
        return <div>{formatCurrency(text)}</div>;
      },
    },
    {
      title: "Dùng",
      dataIndex: "is_used",
      key: "is_used",
      render: (text, record, index) => {
        return record.is_used ? "Đã dùng" : "Chưa dùng";
      },
    },
    {
      title: (
        <div>
          <Button type="primary" onClick={() => discountPopup.current.open()} disabled={props.roleId == 4}>
            Thêm
          </Button>
        </div>
      ),
      render: (text, record, index) => {
        return (
          <Space>
            <Button type="primary" onClick={() => discountPopup.current.open(record.id)} icon={<EditOutlined />}></Button>
            <Popconfirm
              title="Xóa khuyến mãi"
              description="Bạn có muốn xóa khuyến mãi này?"
              disabled={props.roleId == 4}
              onConfirm={() => {
                api.get("Discount/delete?id=" + record.id).then((res) => {
                  message.success("Thành công");
                  props.getLstDiscount(props.customer?.id);
                });
              }}
              okText="Đồng ý"
              cancelText="Không"
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  React.useEffect(() => {
    setFuntionAddNotesRightChat(() => addNotes);
    setFuntionUpdateAddressRightChat(() => setAddress);
  }, [setFuntionAddNotesRightChat, setFuntionUpdateAddressRightChat, props.customer]);

  React.useEffect(() => {
    if (props.customer == null) {
      setMode("edit");
      form.setFieldsValue({ name: selectedComment?.username });
    } else {
      form.setFieldsValue(props.customer);
      setNote2(props.customer.note2);
    }
  }, [props.customer]);

  React.useImperativeHandle(ref, () => ({
    setPhoneInForm(phone) {
      setPhone(phone);
    },
    setModeCustomerChat(data) {
      setMode(data);
    },
  }));

  const printBillJAT = (record) => {
    api.get("CustomerLive/GetByID?id=" + record.customer_id).then((res) => {
      record.jat_respone = JSON.parse(record.jat_create_order_respone);

      api.get("Address/GetAddressByCode?code=" + res.data.address_code).then((res2) => {
        setAddressCus(res2.data);
        setCusPrint(res.data);
        setRecordPrint(record);
        setTypePrint("bill_jat_order");
        setTimeout(() => {
          printRef.current.print();
        }, 1000);
      });
    });
  };

  const deleteOrder = (id) => {
    api.get("LiveOrder/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công!");
      props.getLstOrder();
    });
  };

  const setPhone = (phone) => {
    if (mode != "edit") setMode("edit");
    form.setFieldsValue({ phone: phone });
  };

  const onFinish = (values) => {
    setLoading(true);
    if (!selectedComment.is_zalo) values.fb_user_id = selectedComment?.userid;
    else values.zalo_user_id = selectedComment?.userid;
    if (values.phone && values.phone != "") {
      api
        .get(`CustomerLive/CheckPhoneOrder?id=${values.id ?? 0}&phone=${values.phone}`)
        .then((res) => {
          if (res.cus) {
            saveCustomerConfirm(res.cus, values);
          } else {
            saveCustomer(values);
          }
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    } else {
      saveCustomer(values);
    }
  };

  const saveCustomerConfirm = (cus, values) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Số điện thoại ${values.phone} là của khách hàng ${cus.name}. Bạn có muốn gộp vào khách này không?`,
      onOk() {
        saveCustomer(values);
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const saveCustomer = (values) => {
    api
      .post("CustomerLive/Save", values)
      .then((res) => {
        message.success("Lưu thành công");
        props.setCustomer(res.data);
        props.getLstOrder(res.data.id);
        setMode("info");
        setLoading(false);
        notifyCustomerType();
      })
      .catch((err) => {
        message.error("Lưu thất bại");
        setLoading(false);
      });
  };
  const notifyCustomerType = () => {
    if (selectedComment) {
      api.post("Facebook/NotifyChangeCustomerType?id=" + selectedComment.id).then((res) => {});
    }
  };
  const addNotes = (n) => {
    if (n == null || n == "") return;
    if (props.customer) {
      api.post("CustomerLive/AddNotes", { ID: props.customer.id, Notes: n }).then((res) => {
        setNotes("");
        message.success("Lưu thành công");
        props.getCustomer();
      });
    } else {
      var old = form.getFieldValue("note");
      form.setFieldsValue({ note: old + "\n" + n });
    }
  };

  const setAddress = async (addr) => {
    if (mode != "edit") {
      setMode("edit");
      await props.getCustomer();
    }
    setTimeout(() => {
      form.setFieldsValue({ address: addr });
    }, 300);
  };

  return (
    <div>
      <div style={{ padding: "10px 10px 10px 10px", backgroundColor: "#fff", marginBottom: 10, display: "flex", gap: 30, justifyContent: "space-between" }}>
        <div>
          <div>
            {mode == "edit" && props.customer != null ? (
              <Button
                type="primary"
                shape="round"
                icon={<ArrowLeftOutlined />}
                size={"small"}
                style={{ marginBottom: 10 }}
                onClick={() => {
                  setMode("info");
                }}
              >
                Trở lại
              </Button>
            ) : (
              <></>
            )}
          </div>
          <Row align="middle">
            <Avatar
              size={54}
              src={
                selectedComment.is_zalo
                  ? selectedComment.avatar
                  : `https://graph.facebook.com/${selectedComment?.userid}/picture?height=54&width=54&access_token=${selectedComment?.page_token}`
              }
            />
            <div style={{ marginLeft: 16 }}>
              <Row align="middle" justify="space-between">
                <Title level={5} style={{ margin: 0, display: "inline-block" }}>
                  {props.customer ? props.customer?.name : selectedComment?.username}
                  {props.customer && mode == "info" ? (
                    <Button
                      icon={<EditOutlined />}
                      type="link"
                      style={{ marginLeft: "8px" }}
                      onClick={() => {
                        setMode("edit");
                        props.getCustomer();
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Title>
              </Row>
              {props.customer ? (
                <>
                  <Row align="middle">
                    <div style={{ marginLeft: "12px" }}>
                      <div>
                        <Text>ID: {props.customer.id}</Text> | <Text>Mã: N/A</Text>
                      </div>
                    </div>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </div>
          </Row>
        </div>
        {props.customer ? (
          <TextArea
            onChange={(e) => {
              setNote2(e.target.value);
            }}
            value={note2}
            style={{ flex: 1, maxWidth: 200 }}
            rows={2}
            onPressEnter={() => {
              var data = {
                ID: props.customer.id,
                Notes: note2,
              };
              api.post("CustomerLive/SaveNote2", data).then((res) => {
                message.success("Lưu thành công");
              });
            }}
          />
        ) : (
          <div></div>
        )}
      </div>

      <div style={{ padding: 10, marginBottom: 10, backgroundColor: "#fff" }} hidden={mode != "edit"}>
        <Form form={form} layout="vertical" onFinish={onFinish} disabled={props.roleId == 4}>
          <Form.Item hidden={true} name={"id"} />
          <div style={{ display: "flex", gap: 20 }}>
            <Form.Item
              style={{ flex: 1 }}
              name="name"
              label="Tên khách"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="phone" label="Số điện thoại" style={{ flex: 1 }}>
              <Input />
            </Form.Item>
          </div>

          <Form.Item name="note" label="Ghi chú">
            <TextArea rows={3} />
          </Form.Item>
          <Form.Item name={"address_code"} label={"Địa chỉ"}>
            <AddressPicker />
          </Form.Item>
          <Form.Item name="address">
            <Input placeholder="Địa chỉ" />
          </Form.Item>
          <div style={{ display: "flex", gap: 50 }}>
            <Form.Item name={"is_wholesale"}>
              <Radio.Group>
                <Radio value={true}> Khách sỉ </Radio>
                <Radio value={false}> Khách lẻ </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item valuePropName="checked" name={"is_warning"}>
              <Checkbox>Cảnh báo</Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" name={"is_goods_bomb"}>
              <Checkbox>Bom hàng</Checkbox>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Lưu
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>

      <div style={{ paddingLeft: "10px", borderBottom: "2px solid #f0f0f0" }} hidden={mode == "edit"}>
        <Row style={{ marginTop: 8 }}>
          <Col span={24}>
            <Text>
              <Avatar size={20} shape="square" style={{ backgroundColor: "#f0f0f0" }} icon={<EditOutlined style={{ color: "#b3b3b3" }} />}></Avatar>{" "}
              <div style={{ fontSize: 14, color: "blueviolet", fontWeight: "bold" }}>
                {props.customer?.note ? props.customer.note.split("\n").map((x) => <div>{x}</div>) : <></>}
              </div>
              <div></div>
            </Text>
            <Text>
              <Avatar size={20} shape="square" style={{ backgroundColor: "#f0f0f0" }} icon={<PhoneFilled style={{ color: "#b3b3b3" }} />}></Avatar>{" "}
              {props.customer?.phone}
            </Text>

            <br />
            <Text italic>
              <Avatar
                size={20}
                shape="square"
                style={{ backgroundColor: "#f0f0f0", marginRight: "2px" }}
                icon={<EnvironmentFilled style={{ color: "#b3b3b3" }} />}
              />
              {props.customer?.address_str ? props.customer.address_str : "Chưa có địa chỉ"}
            </Text>
          </Col>
        </Row>
        {props.customer && (props.customer.is_warning || props.customer.is_goods_bomb) ? (
          <div style={{ marginTop: 10 }}>
            {props.customer.is_warning ? <Tag color="#f50">Cảnh báo</Tag> : <></>}&nbsp; &nbsp;
            {props.customer.is_goods_bomb ? <Tag color="#f90707">Boom hàng</Tag> : <></>}
          </div>
        ) : (
          <></>
        )}
        <Row style={{ marginTop: 8 }}>
          <Col>
            <div
              style={{
                display: "inline-block",
                padding: "4px 12px",
                border: "1px solid #d9d9d9",
                borderRadius: "30px",
                backgroundColor: "#fff",
              }}
            >
              <Text strong>Tổng doanh số:&nbsp; {formatCurrency(props.lstOrder.reduce((accumulator, x) => accumulator + x.total_price, 0))}</Text>
            </div>
          </Col>
        </Row>

        <div>
          <Collapse
            expandIconPosition="right"
            style={{ border: "none", background: "none", borderBottom: "2px solid #f0f0f0", borderRadius: "0px" }}
            defaultActiveKey={"1"}
          >
            <Panel
              header={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>
                    Đơn hàng
                    <Badge count={props.lstOrder.length} style={{ backgroundColor: "#52c41a", marginLeft: "8px" }} />
                    &nbsp;
                    {`Tỉ lệ giao hàng thành công ${Math.floor(
                      props.lstOrder.length == 0 ? 0 : ((props.lstOrder.filter((x) => x.jat_status == "Ký nhận").length / props.lstOrder.length) * 10000) / 100
                    )}%`}
                  </span>
                </div>
              }
              key="1"
              style={{ border: "none", background: "none" }}
            >
              <div>
                <Table
                  columns={columns}
                  dataSource={props.lstOrder}
                  size="small"
                  className={styles.customTable}
                  scroll={{
                    y: 55 * 5,
                  }}
                ></Table>
              </div>
            </Panel>
          </Collapse>
          <Collapse expandIconPosition="right" style={{ border: "none", background: "none", borderBottom: "2px solid #f0f0f0", borderRadius: "0px" }}>
            <Panel
              header={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>Khuyến mãi</span>
                </div>
              }
              key="1"
              style={{ border: "none", background: "none" }}
            >
              <div>
                <Table
                  columns={columDiscount}
                  dataSource={props.lstDiscount}
                  size="small"
                  className={styles.customTable}
                  scroll={{
                    y: 55 * 5,
                  }}
                ></Table>
              </div>
            </Panel>
          </Collapse>
          <Collapse
            expandIconPosition="right"
            style={{ border: "none", background: "none", borderBottom: "2px solid #f0f0f0", borderRadius: "0px" }}
            defaultActiveKey={"2"}
          >
            <Panel header={<div>Ghi chú</div>} key="2" style={{ border: "none", background: "none" }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Input placeholder="Nhập ghi chú" style={{ width: "85%" }} value={notes} onChange={(e) => setNotes(e.target.value)} />
                <Button
                  disabled={props.roleId == 4}
                  type="primary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => {
                    addNotes(notes);
                  }}
                >
                  + Thêm
                </Button>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
      <JATCreateOrderPopup ref={jatCreateOrderPopup} reload={props.getLstOrder} printBillJAT={printBillJAT}></JATCreateOrderPopup>
      <JATCancelOrderPopup ref={jatCancelOrderPopup} reload={props.getLstOrder}></JATCancelOrderPopup>
      <DiscountPopup ref={discountPopup} reload={props.getLstDiscount} customer={props.customer}></DiscountPopup>
      <ChangeSession ref={changeSession} reload={props.getLstOrder} />
      <TrackingPopup ref={trackingRef} />
      <div style={{ display: "none" }}>
        <Print
          type={typePrint}
          data={recordPrint}
          cus={cusPrint}
          config={props.shopConfig}
          shopAddres={props.addressShop}
          customerAddres={addresCus}
          ref={printRef}
          details={lstDetail}
        />
      </div>
    </div>
  );
});
export default CustomerChat;
