import React, { useState } from "react";
import { Button, Modal, Form, Input, InputNumber, Row, Col, Select } from "antd";
import { Print } from "component/print";
import api from "api";
import { formatLongDate, formatCurrency } from "common/Format2";
import dayjs from "dayjs";
import { ChatData } from ".";
import * as FacebookAPI from "./FacebookAPI";
import moment from "moment";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const { TextArea } = Input;

const CustomRightClick = () => {
  const [visible, setVisible] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [data, setData] = React.useState({});
  const [lstSession, setLstSession] = React.useState([]);
  const [cmt, setCmt] = React.useState();
  const printRef = React.useRef();
  const [form] = Form.useForm();
  const [time, setTime] = React.useState(0);
  const inputRef = React.useRef(null);
  const { setHandleContextMenu, selectedComment, customer, funtionGetCustomerRightChat, lstDetailComment } = React.useContext(ChatData);
  React.useEffect(() => {
    setHandleContextMenu(() => handleContextMenu);
  }, [setHandleContextMenu, selectedComment, customer]);

  const getListSession = () => {
    api.get("LiveOrder/GetListSession").then((res) => {
      var data = res.lst.map((item) => {
        return {
          value: item.id,
          label:
            item.live_content && item.live_content != ""
              ? `${item.live_content}`
              : `Phiên live ${formatLongDate(item.start_time)} - ${item.end_time ? formatLongDate(item.end_time) : "Chưa kết thúc"}`,
          start_time: dayjs(item.start_time),
        };
      });
      setLstSession(data);

      form.setFieldsValue({ session_id: data[0].value });
    });
  };

  const handleContextMenu = (event, cus, cmt) => {
    event.preventDefault();
    setCmt(cmt);
    const selected = window.getSelection().toString().trim();

    if (selected && window.getSelection().containsNode(event.target, true)) {
      setVisible(true);
      setDisableForm(false);
      getListSession();
      setTimeout(() => {
        form.setFieldsValue({ product_id: selected, name: cus ? cus.name : selectedComment?.username, cmt_id: cmt.id });
        inputRef.current.focus({
          cursor: "end",
        });
      }, 500);
    } else {
      setVisible(false);
    }
  };

  const onFinish = (values) => {
    setDisableForm(true);
    var session = lstSession.find((x) => x.value == values.session_id);
    if (session) {
      var now = dayjs();
      setTime(now.diff(session.start_time, "second"));
    } else {
      setTime(-1);
    }
    values.price = values.price * 1000;
    setData(values);
    setTimeout(() => {
      printRef.current.print();
    }, 500);
  };

  const close = () => {
    setVisible(false);
    form.resetFields();
  };

  const afterPrint = () => {
    close();
    saveOrder();
    replyText();
  };
  const replyprivateText = () => {
    var lastcmt = getLastDetailComment(lstDetailComment);
    var message = "Đã chốt mã " + data.product_id + " với giá: " + formatCurrency(data.price);
    FacebookAPI.privateMessage(lastcmt?.id, message, selectedComment?.page_token).then(
      (res) => {
        api
          .post("Facebook/UpdateIsSendInbox", {
            id: lastcmt?.id,
          })
          .then((res) => {});
      },
      (error) => {
        error = JSON.parse(error).error;
        console.log(error);
      }
    );
  };
  const replyText = () => {
    var message = "Đã chốt mã: " + data.product_id + " với giá: " + formatCurrency(data.price);
    FacebookAPI.replyFbAPI(selectedComment?.id, selectedComment?.pageid, selectedComment?.page_token, message, "inbox", null, selectedComment?.userid).then(
      (res) => {},
      (error) => {
        error = JSON.parse(error).error;
        console.log(error);
        replyprivateText();
      }
    );
    if (cmt.typeof == "comment") {
      message += " cho: " + cmt.username;
      FacebookAPI.replyFbAPI(cmt?.postid, selectedComment?.pageid, selectedComment?.page_token, message, "comment", null, selectedComment?.userid).then(
        (res) => {},
        (error) => {
          error = JSON.parse(error).error;
        }
      );
    }
  };
  const getLastDetailComment = (comments) => {
    if (!comments || comments.length === 0) return [];
    var usercomments = comments.filter((comment) => comment.typeof === "comment" && comment.userid !== comment.pageid);
    var sortlist = usercomments.sort((a, b) => moment(b.time) - moment(a.time));
    return sortlist[0];
    setDisableForm(false);
  };
  const saveOrder = () => {
    var dataOd = { ...data };
    if (!selectedComment.is_zalo) dataOd.fb_user_id = selectedComment?.userid;
    else dataOd.zalo_user_id = selectedComment?.userid;
    dataOd.customer_id = customer?.id;
    api.post("LiveOrder/SaveOrderLiveFB", dataOd).then((res) => {
      if (customer == null) funtionGetCustomerRightChat();
    });
  };

  return (
    <Modal
      title="In Bill"
      open={visible}
      onCancel={() => {
        close();
      }}
      footer={[]}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} disabled={disableForm}>
        <Form.Item name={"cmt_id"} hidden></Form.Item>
        <Form.Item name={"session_id"} label="Phiên live">
          <Select placeholder={"chọn phiên live"} options={lstSession} allowClear />
        </Form.Item>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              label="Tên"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Mã số"
              name="product_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item label="Giá" name="price">
              <InputNumber
                ref={inputRef}
                style={{ width: " 100%" }}
                inputMode="numeric"
                min={0}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                step={1000}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Ghi chú" name="notes">
              <TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Print
            </Button>
          </Form.Item>
        </div>
      </Form>

      <div style={{ display: "none" }}>
        <Print type="bill_live" data={data} time={time} ref={printRef} afterPrint={afterPrint} />
      </div>
    </Modal>
  );
};

export default CustomRightClick;
