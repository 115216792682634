import React from "react";
import { Button, Tag, Card, DatePicker } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import { formatCurrency } from "common/Format2";
import { useParams, useNavigate } from "react-router-dom";
import AntTable from "component/common/AntTable";
import realtime from "common/Realtime";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const Debt = () => {
  const [totalDebt, setTotalDebt] = React.useState(0);
  const [totalDebtWorker, setTotalDebtWorker] = React.useState(0);
  const [totalDebtSupplier, setTotalDebtSupplier] = React.useState(0);
  const [debtDate, setDebtDate] = React.useState();
  const [transactionDate, setTransactionDate] = React.useState(dayjs().subtract(1, "month"));
  console.log(transactionDate);
  const tbDebt = React.useRef();
  const tbDebt1 = React.useRef();
  const tbDebt2 = React.useRef();
  const tbTransaction = React.useRef();
  const tbTransaction1 = React.useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (tbDebt.current.reload) tbDebt.current.reload();
    realtime.subscribe("customer_transaction", (noti) => {
      tbDebt.current.reload();
    });
  }, []);

  React.useEffect(() => {
    tbDebt.current.reload();
    tbDebt1.current.reload();
    tbDebt2.current.reload();
  }, [debtDate]);

  React.useEffect(() => {
    tbTransaction.current.reload();
    tbTransaction1.current.reload();
  }, [transactionDate]);

  const colDebt = [
    {
      title: "Stt",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "customer_name",
    },
    {
      title: "Loại",
      dataIndex: "is_wholesale",
      render: (text, record, index) => {
        return record.is_wholesale ? <Tag color="green">Khách sỉ</Tag> : <Tag color="blue">Khách lẻ</Tag>;
      },
    },
    {
      title: "Số tiền",
      dataIndex: "profix",
      render: (text, record, index) => {
        return formatCurrency(record.profit);
      },
    },
  ];

  const colTransaction = [
    {
      title: "Stt",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Loại",
      dataIndex: "is_wholesale",
      render: (text, record, index) => {
        return record.is_wholesale ? <Tag color="green">Khách sỉ</Tag> : <Tag color="blue">Khách lẻ</Tag>;
      },
    },
  ];

  const colDebt2 = [
    {
      title: "Stt",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "customer_name",
    },
    {
      title: "Số tiền",
      dataIndex: "profix",
      render: (text, record, index) => {
        return formatCurrency(record.profit);
      },
    },
  ];

  return (
    <div>
      <Card style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <DatePicker format={"DD/MM/YYYY"} size="large" onChange={setDebtDate} value={debtDate} />
        </div>
        <div style={{ textAlign: "center" }}>
          <b>Khách hàng</b>
        </div>
        <AntTable
          columns={colDebt}
          ajax="Report/ReportCustomerDebt"
          customSearch={{ Date: debtDate }}
          ref={tbDebt}
          title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(totalDebt)}`} </div>}
          onGetDataFinish={(res) => {
            setTotalDebt(res.sum ?? 0);
          }}
        ></AntTable>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <b>Đầu vào</b>
        </div>
        <AntTable
          columns={colDebt2}
          ajax="Report/ReportSuplierDebt"
          customSearch={{ Date: debtDate }}
          ref={tbDebt1}
          title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(totalDebtSupplier)}`} </div>}
          onGetDataFinish={(res) => {
            setTotalDebtSupplier(res.sum ?? 0);
          }}
        ></AntTable>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <b>Thợ</b>
        </div>
        <AntTable
          columns={colDebt2}
          ajax="Report/ReportWorkerDebt"
          customSearch={{ Date: debtDate }}
          ref={tbDebt2}
          title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(totalDebtWorker)}`} </div>}
          onGetDataFinish={(res) => {
            setTotalDebtWorker(res.sum ?? 0);
          }}
        ></AntTable>
      </Card>
      <Card>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <DatePicker format={"DD/MM/YYYY"} size="large" onChange={setTransactionDate} value={transactionDate} />
        </div>
        <div style={{ textAlign: "center" }}>
          <b>Khách hàng có thanh toán</b>
        </div>
        <AntTable
          columns={colTransaction}
          ajax="Report/CustomerHaveTransaction"
          customSearch={{ Date: transactionDate }}
          ref={tbTransaction}
          onGetDataFinish={(res) => {
            setTotalDebt(res.sum ?? 0);
          }}
        ></AntTable>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <b>Khách hàng không thanh toán</b>
        </div>
        <AntTable
          columns={colTransaction}
          ajax="Report/CustomerNoHaveTransaction"
          customSearch={{ Date: transactionDate }}
          ref={tbTransaction1}
          onGetDataFinish={(res) => {
            setTotalDebtSupplier(res.sum ?? 0);
          }}
        ></AntTable>
      </Card>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            navigate("/");
          }}
        >
          Trở lại
        </Button>
      </div>
    </div>
  );
};

export default Debt;
