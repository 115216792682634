import { Select, Card, Checkbox, Button, Modal, message, Form, Input, Space, InputNumber, Divider } from "antd";
import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import AddressPicker from "./address_picker";
import dayjs from "dayjs";
import api from "api";
import { createSearchText } from "common/Text";

const { confirm } = Modal;
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
const { TextArea } = Input;
const OrderDetail = React.forwardRef((props, ref) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dataOD, setDataOD] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const [loadingCustomer, setLoadingCustomer] = React.useState(true);
  const [lstCustomer, setLstCustomer] = React.useState([]);
  const [form] = Form.useForm();
  React.useImperativeHandle(ref, () => ({
    open(id) {
      getListCustomer();
      setIsModalOpen(true);
      if (id) {
        api.get("LiveOrder/GetOrderInfo?id=" + id).then((res) => {
          var data = {
            id: res.data.order.id,
            customer_name: res.data.cus ? res.data.cus.name : res.data.order.customer_name,
            customer_phone: res.data.cus?.phone,
            notes: res.data.order.notes,
            is_freeship: res.data.order.is_freeship,
            weight: res.data.order.weight,
            details: res.data.details,
            customer_id: res.data.order.customer_id,
          };
          form.setFieldsValue(data);
          setDataOD(data);
        });
      } else {
        form.setFieldsValue({ session_id: props.sessionID, is_freeship: false });
      }
    },
  }));

  const getListCustomer = () => {
    api.get("CustomerLive/GetList").then((res) => {
      setLstCustomer(res.data);
      setLoadingCustomer(false);
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const saveOrderConfirm = (cus, values) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Số điện thoại ${values.customer_phone} là của khách hàng ${cus.name}. Bạn có muốn thay đổi đơn hàng qua khách này không?`,
      onOk() {
        saveOrder(values);
      },
      onCancel() {},
    });
  };

  const saveOrder = (values) => {
    api.post("LiveOrder/SaveEditOrder", values).then((res) => {
      message.success("Lưu thành công");
      handleCancel();
      setTimeout(() => {
        props.getData();
      }, 500);
    });
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues.details) {
      var quantity = allValues.details.filter((x) => x && x.price && x.quantity && x.price > 0).reduce((acc, obj) => acc + obj.quantity, 0);
      if (quantity >= 5) form.setFieldsValue({ is_freeship: true });
    }
  };

  const onFinish = (values) => {
    if (!values.details || values.details.length == 0) {
      message.error("Vui lòng nhập sản phẩm");
      return;
    }
    if (values.customer_id) {
      var cus = lstCustomer.find((x) => x.id == values.customer_id);
      if (values.customer_name == null || values.customer_name == "") values.customer_name = cus?.name;
    }
    if (values.id && values.id != 0)
      if (values.customer_phone && values.customer_phone != "") {
        api
          .get(`CustomerLive/CheckPhoneOrder?id=${values.customer_id}&phone=${values.customer_phone}`)
          .then((res) => {
            if (res.cus) {
              saveOrderConfirm(res.cus, values);
            } else {
              saveOrder(values);
            }
          })
          .catch((err) => {
            message.error(err.message);
          });
      } else {
        saveOrder(values);
      }
    else
      api.post("LiveOrder/SaveNewOrder", values).then((res) => {
        message.success("Lưu thành công");
        handleCancel();
        props.getData();
      });
  };
  return (
    <Modal
      title="Chỉnh sửa đơn hàng"
      open={isModalOpen}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Hủy
        </Button>,
        <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={handleOk}>
          Lưu
        </Button>,
      ]}
      maskClosable={false}
      closeIcon={false}
    >
      <Form
        layout={{
          labelCol: {
            span: 8,
          },
          wrapperCol: {
            span: 16,
          },
        }}
        form={form}
        onValuesChange={handleValuesChange}
        name="control-hooks"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item hidden={true} name={"id"} />
        <Form.Item hidden={true} name={"session_id"} />
        <Form.Item hidden={true} name={"customer_name"} />
        <Form.Item
          name="customer_id"
          label="Khách hàng"
          required={true}
          rules={[
            {
              validator: (rule, value) => {
                var data = form.getFieldsValue();
                return new Promise((resolve, error) => {
                  if (data.customer_id == null && (data.customer_name == null || data.customer_name == "")) {
                    error();
                  } else {
                    resolve();
                  }
                });
              },
              message: "Vui lòng nhập tên hoặc chọn khách hàng!",
            },
          ]}
        >
          {/* <Input style={{ textAlign: "right" }} /> */}
          <Select
            loading={loadingCustomer}
            style={{
              width: "100%",
            }}
            searchValue={form.getFieldValue("customer_name")}
            onChange={(e, f) => {
              if (f != null) {
                form.setFieldsValue({ customer_phone: f.phone, customer_address_code: f.address_code, address: f.address, customer_id: f.id });
                setSearchValue(f.name);
              } else {
                form.setFieldsValue({ customer_phone: null, customer_address_code: null, customer_name: "", address: null, customer_id: null });
                setTimeout(() => {
                  setSearchValue(Math.random().toString());
                }, 500);
              }
            }}
            options={lstCustomer}
            fieldNames={{ label: "name", value: "id" }}
            showSearch={true}
            allowClear={true}
            autoClearSearchValue={true}
            onSearch={(e) => {
              setSearchValue(e);
              if (e != null && e != "") {
                form.setFieldsValue({ customer_name: e });
              }
              form.validateFields(["customer_id"]);
            }}
            filterOption={(key, option) => {
              if (key == "") return true;
              key = createSearchText(key);
              var label = createSearchText(option.name);
              if (label.includes(key)) return true;
              return false;
            }}
            virtual={false}
          />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.customer_id !== currentValues.customer_id}>
          {({ getFieldValue }) => {
            return (
              <Form.Item name="customer_phone" label="Số điện thoại">
                <Input style={{ textAlign: "right" }} />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.customer_phone !== currentValues.customer_phone}>
          {({ getFieldValue }) => {
            return getFieldValue("customer_phone") !== "" && getFieldValue("customer_phone") != null ? (
              <>
                <Form.Item name={"customer_address_code"} label={"Địa chỉ"}>
                  <AddressPicker />
                </Form.Item>
                <Form.Item name="customer_address">
                  <Input placeholder="Địa chỉ" />
                </Form.Item>
              </>
            ) : null;
          }}
        </Form.Item>
        <div style={{ display: "flex", gap: 50 }}>
          <Form.Item name="is_freeship" valuePropName="checked">
            <Checkbox>Freeship</Checkbox>
          </Form.Item>
          <Form.Item name={"weight"} label="Cân nặng">
            <InputNumber min={0} suffix="KG" />
          </Form.Item>
        </div>

        <Form.Item name="notes" label="Ghi chú">
          <TextArea />
        </Form.Item>
        <Divider>Sản Phẩm</Divider>
        <Form.List
          name={"details"}
          // rules={[
          //   {
          //     validator: (rule, value) => {
          //       return new Promise((resolve, error) => {
          //         if (value && value.length > 0) {
          //           resolve();
          //         } else {
          //           message.error("Vui lòng thêm sản phẩm!");
          //           error();
          //         }
          //       });
          //     },
          //     message: "Vui lòng thêm sản phẩm!",
          //   },
          // ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space style={{ marginBottom: 5 }}>
                    <Form.Item
                      name={[name, "product"]}
                      rules={[
                        {
                          required: true,
                          message: "Nhập mã sản phẩm",
                        },
                      ]}
                    >
                      <Input
                        disabled={form.getFieldValue("details")[index] ? true : false}
                        placeholder="Mã SP"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[name, "quantity"]}
                      rules={[
                        {
                          required: true,
                          message: "Nhập SL",
                        },
                      ]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      name={[name, "price"]}
                      rules={[
                        {
                          required: true,
                          message: "Nhập giá",
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </Form.Item>
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} style={{ marginTop: 15 }} block icon={<PlusOutlined />}>
                    Thêm sản phẩm
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
});
export default OrderDetail;
